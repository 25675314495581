import { Suspense, lazy, useEffect } from 'react'
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom'
import ScrollToTop from 'react-router-scroll-top'
import { useTranslation } from 'react-i18next'
import { getUserIP } from '@/apis/cloudfare'
import { ConfigProvider, notification } from 'antd'
import Theme from '@/containers/Theme'
import './i18n'
import './App.less'

import RoutePrivate from '@/containers/RoutePrivate'
import RoutePublic from '@/containers/RoutePublic'

import Loading from '@/components/Loading'
import ModalForceTasterOut from '@/components/ModalForceTasterOut'
import Error404 from '@/components/Error/404'
import useDirection from '@/hooks/useDirection'
import componentLoader from './utils/componentLoader'
// import AnnouncementBar from './components/AnnouncementBar'

const Welcome = lazy(() => componentLoader(() => import('@/pages/Welcome')));
const Signup = lazy(() => componentLoader(() => import('@/pages/Signup')));
const SignupWalkin = lazy(() => componentLoader(() => import('@/pages/SignupWalkin')));
const Signin = lazy(() => componentLoader(() => import('@/pages/Signin')));
const SigninWithFacebook = lazy(() => componentLoader(() => import('@/pages/SigninWithFacebook')));
const SigninWithGoogle = lazy(() => componentLoader(() => import('@/pages/SigninWithGoogle')));
const SigninWithToken = lazy(() => componentLoader(() => import('@/pages/SigninWithToken')));
const ForgotPassword = lazy(() => componentLoader(() => import('@/pages/ForgotPassword')));
const ResetPassword = lazy(() => componentLoader(() => import('@/pages/ResetPassword')));
const ResendVerificationEmail = lazy(() => componentLoader(() => import('@/pages/ResendVerificationEmail')));
const VerifyEmail = lazy(() => componentLoader(() => import('@/pages/VerifyEmail')));
const ConnectWithSocial = lazy(() => componentLoader(() => import('@/pages/ConnectWithSocial')));
const TasterDashboard = lazy(() => componentLoader(() => import('@/pages/TasterDashboard')));
const Profile = lazy(() => componentLoader(() => import('@/pages/Profile')));
const Impersonate = lazy(() => componentLoader(() => import('@/pages/Impersonate')));
const SSOIdentify = lazy(() => componentLoader(() => import('@/pages/SSOIdentify')));
const Survey = lazy(() => componentLoader(() => import('@/pages/Survey')));
const ShareSurvey = lazy(() => componentLoader(() => import('@/pages/ShareSurvey')));
const QrRedirect = lazy(() => componentLoader(() => import('@/pages/QrRedirect')));
const NotVerified = lazy(() => componentLoader(() => import('@/pages/NotVerified')));
const Under18 = lazy(() => componentLoader(() => import('@/pages/Under18')));
const CodeExpired = lazy(() => componentLoader(() => import('@/pages/CodeExpired')));
const WalkinLanding = lazy(() => componentLoader(() => import('@/pages/WalkinLanding')));
const TwoFactorAuthentication = lazy(() => componentLoader(() => import('@/pages/TwoFactorAuthentication')));


const App = () => {
  const { t } = useTranslation()
  const [direction] = useDirection()

  useEffect(() => {
    getUserIP()
  }, [])

  useEffect(() => {
    const splashScreen = document.querySelector('#splashscreen')
    if (splashScreen) {
      splashScreen.classList.add('hidden')
    }
  }, [])

  useEffect(() => {
    const onlineFunction = () => {
      notification.destroy()
      notification.success({
        message: t('internet.online'),
        placement: 'bottomLeft'
      })
    }

    const offlineFunction = () => {
      notification.destroy()
      notification.error({
        duration: 0,
        message: t('internet.offline'),
        placement: 'bottomLeft'
      })
    }

    window.addEventListener('online', onlineFunction)
    window.addEventListener('offline', offlineFunction)

    return () => {
      window.removeEventListener('online', onlineFunction)
      window.removeEventListener('offline', offlineFunction)
    }
  }, [])

  return (
    <ConfigProvider direction={direction}>
      <Router>
      <ScrollToTop>
        <Theme>
          {/* <AnnouncementBar text={t('announcementBar')}/> */}
          <Suspense fallback={<Loading />}>
            <Switch>
              <RoutePublic exact path='/'>
                <Welcome />
              </RoutePublic>

              <RoutePublic path='/sign-up'>
                <Signup />
              </RoutePublic>

              <RoutePublic path='/sign-up-walkin'>
                <SignupWalkin />
              </RoutePublic>

              <RoutePublic path='/sign-in'>
                <Signin />
              </RoutePublic>

              <RoutePublic path='/forgot-password'>
                <ForgotPassword />
              </RoutePublic>

              <RoutePublic path='/resend-verification-email'>
                <ResendVerificationEmail />
              </RoutePublic>

              <RoutePublic path='/onboarding/reset-password'>
                <ResetPassword />
              </RoutePublic>

              <RoutePublic path='/verify-email'>
                <VerifyEmail />
              </RoutePublic>

              <RoutePublic path='/requirement-information'>
                <ConnectWithSocial />
              </RoutePublic>

              <RoutePrivate path='/dashboard'>
                <TasterDashboard />
              </RoutePrivate>

              <RoutePrivate path='/profile'>
                <Profile />
              </RoutePrivate>

              <RoutePrivate path='/2fa'>
                <TwoFactorAuthentication />
              </RoutePrivate>

              <RoutePublic path='/walkin-landing/:surveyId'>
                <WalkinLanding />
              </RoutePublic>

              <Route path='/impersonate' component={Impersonate} />

              <Route path='/qr-code/:id' component={QrRedirect} />

              <Route path='/signin-with-token' component={SigninWithToken} />

              <Route path='/sso/identify' component={SSOIdentify} />

              <Route
                path='/signin-with-facebook'
                component={SigninWithFacebook}
              />

              <Route path='/signin-with-google' component={SigninWithGoogle} />

              <Route
                path='/survey/619f6ce40dbf40200ca0993c'
                component={() => {
                  window.location.href =
                    'https://blc-sales.flavorwiki.com/share/bunge-pain-point-v1'
                  return null
                }}
              />

              <Route
                path='survey/619f6ce40dbf40200ca0993c/instructions'
                component={() => {
                  window.location.href =
                    'https://blc-sales.flavorwiki.com/share/bunge-pain-point-v1'
                  return null
                }}
              />

              <Route path='/survey/:surveyId' component={Survey} />

              <Route
                path='/share/bunge-pain-point-v1'
                component={() => {
                  window.location.href =
                    'https://blc-sales.flavorwiki.com/share/bunge-pain-point-v1'
                  return null
                }}
              />

              <Route path='/share/:uniqueName' component={ShareSurvey} />

              <Route path='/not-verified' component={NotVerified} />

              <Route path='/under-18' component={Under18} />

              <Route path='/code-expired' component={CodeExpired} />

              <Route path='*' component={Error404} />
            </Switch>
          </Suspense>

          <ModalForceTasterOut />
        </Theme>
      </ScrollToTop>
      </Router>
    </ConfigProvider>
  )
}

export default App
